<template>
<div>
        <!-- Use for text with the hyperlink -->
          <p v-if="linked" class="text-start sam-paragraph mb-0" v-html="text"></p>

          <!-- Use for text without the hyperlink -->
          <p v-if="!linked && !price" class="text-start sam-paragraph mb-0" :class="{'ellipsis': ellipsis, 'bolded': bolded, 'tableItem': tableItem}">{{text}}</p>
  
          <!-- Use for text without the hyperlink -->
          <p v-if="price || lightText" class="text-start sam-paragraph price mb-0" :class="{'tableItem': tableItem}">{{text}}</p>

          <!-- directly use -->
          <!-- <p class="text-start paragraph"> 
          Paragraph 1rem/1.2 (16/24px) can’t be longer than 1/2 column. Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
          <SamHyperlink link="#" text="with a hyperlink"/>
          nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt laborum.
          </p> -->
</div>
</template>

<script>

// For directly use
// import SamHyperlink from '@/components/fragments/SamHyperlink.vue'

export default {
  components: {},
  props: {
    text: {
      type: String,
    },
    linked: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Boolean,
      default: false
    },
    lightText : {
      type: Boolean,
      default: false
    },
    ellipsis: {
      type: Boolean,
      default: false
    },
    bolded: {
      type: Boolean,
      default: false
    },
    tableItem: {
      type: Boolean,
      default: false
    }
  },
  name: 'SamParagraph'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .sam-paragraph {
    font-size: $p-font-size;
    line-height: $line-height-base;
    font-family: $mainFontFamily;
    font-weight: $mainFontWeight;
    word-break: break-word;
  }

  .hyperlink {
    color: $mainColor;
    font-family: $boldFontStyle;
    transition: $mainTransition;

      &:hover {
        opacity: 0.8;
         color: $mainColor;
      }
  }

  .price {
    color: $greyColor
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    margin-right: 4px;
  }

  .bolded {
    font-family: $boldFontStyle;
  }

  @media(min-width: 768px) {
    .tableItem {
      margin-left: 9px !important;
    }
  }
</style>